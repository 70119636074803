import React from 'react';
import logo from './logo.svg';
import './App.css';
import jack_of_spades from './assets/PNG-cards-1.3/jack_of_spades.png'
import { AppBar, Toolbar, Typography } from '@mui/material';

function App() {
  return (
    <div className="App">
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h5' component='div'>
            Pisquashamo Pitch
          </Typography>
        </Toolbar>
      </AppBar>
      <br></br>
      <img src={jack_of_spades}/>
      <br></br>
      <Typography variant='h6' component='div'>
        Coming soon...
      </Typography>
    </div>
  );
}

export default App;
